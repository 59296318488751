import React from "react";

import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>

      <div className="container">

        <Typography variant="h3" gutterBottom>
          About Astrosetup.com
        </Typography>
        <Typography variant="body1" gutterBottom>
          Astrosetup.com is a project which allows astronomy enthusiasts share there beloved astro setups with other
          interested stargazers. Initially it will focus on astrophotography configurations. In essence, its a handy
          mega astronomy calculator that aims to increase community and sharing of knowledge.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Currently, it offers a setup builder that will allow users to construct their telescope from a huge database
          of components. As the users add components to the setup, calculations will begin to be shown to the users.
          Such as - camera details, configuration FoV, approximate weight of the components and the payload capacity of
          the mount in use. Once saved, the setup can be linked to in order to show others the configuration. <Link
          to={"/setups/5fe0ce65c5507c0044b5333a"}>Like this setup</Link>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Additionally, users can explore other users setups, filtering for setups that feature a specific, or
          combination of, components. In this way users will be able to see similar setups to their own, and see what is
          possible!
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the future it will be able to be used by anybody who is looking to share their setup. I am focusing on
          astrophotography at the moment as I am much more familiar with the manufacturers and caclulations involved
          with imaging. So hold tight my visual friends, its coming soon!
        </Typography>
      </div>
    </>
  );
};

export default AboutPage;
