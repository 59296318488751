import React from "react";
import "bulma/css/bulma.css";


import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./components/layout/Layout";

import SetupsListPage from "./pages/SetupsListPage";
import CreateSetupPage from "./pages/CreateSetupPage";
import SetupViewPage from "./pages/SetupViewPage";
import ComponentViewPage from "./pages/ComponentViewPage";
import ViewUserProfilePage from "./pages/ViewUserProfilePage";
import AboutPage from "./pages/AboutPage";
import SetupEditPage from "./pages/EditSetupPage";
import MyAccountPage from "./pages/MyAccountPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/setups/:id" component={SetupViewPage} />
            <Route exact path="/setups" component={SetupsListPage} />
            <Route exact path="/setups/edit/:id" component={SetupEditPage} />
            <Route exact path="/components/:id" component={ComponentViewPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/user/:id" component={ViewUserProfilePage} />
            <Route exact path="/create" component={CreateSetupPage} />
            <Route exact path="/account" component={MyAccountPage} />
            <Route exact path="/" component={HomePage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;
