import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";


import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useAuth0 } from "../../contexts/auth0-context";
import ComponentListCard from "./ComponentListCard";
import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";


const AddComponentSearchInput = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    let url = "/api/components/";
    console.log('props.model', props.model)
    if (props.model === "Telescope") {
      url = "/api/components/telescopes";
    }
    if (props.model === "Camera") {
      url = "/api/components/cameras";
    }
    if (props.model === "Mount") {
      url = "/api/components/mounts";
    }
    console.log('GOT MODEL', props.model)
    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await axios.get(url);

      if (active) {
        setOptions(res.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, props.mode, props.model]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <div>
      <Autocomplete
        id="AddComponentSearch"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        blurOnSelect={true}
        options={options}
        loading={loading}
        selectOnFocus={true}
        getOptionLabel={option => option.manufacturer.toLowerCase() + " " + option.model.toLowerCase()}
        onChange={(event, option) => option && props.handleClick(event, option)}
        renderInput={(params) => (
          <TextField {...params} label="Search for component" margin="normal" variant="outlined"
                     InputProps={{
                       ...params.InputProps,
                       endAdornment: (
                         <React.Fragment>
                           {loading ? <CircularProgress color="inherit" size={20} /> : null}
                           {params.InputProps.endAdornment}
                         </React.Fragment>
                       )
                     }} />
        )}
      />
      {/*<Typography variant={"caption"}>Note: each category can contain multiple components (camera + telescope etc)</Typography>*/}
    </div>
  );
};


export default AddComponentSearchInput;
