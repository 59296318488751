import React from "react";
import ProfileContainer from "../components/users/ProfileContainer";

const MyAccountPage = () => {

  return (
    <>
      <ProfileContainer />
    </>
  );
};

export default MyAccountPage;
