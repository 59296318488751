export const getTelescope = (components) =>
  components && components.filter((component) => component.__t === "Telescope")[0];

export const getCamera = (components) =>
  components && components.filter((component) => component.__t === "Camera")[0];

export const getMount = (components) =>
  components && components.filter((component) => component.__t === "Mount"[0]);

export const getMountVisualPayload = (mount) =>
  mount && mount.visual_payload ? mount.visual_payload / 1000 : 0;

export const getMountImagingPayload = (mount) =>
  mount && mount.imaging_payload ? mount.imaging_payload / 1000 : 0;

export const getMountPayloadCapacity = (mount) =>
  mount && mount.payload_capacity ? mount.payload_capacity / 1000 : 0;

export const focalRatio = (telescope) =>
  telescope && (telescope && telescope.focal_length / telescope.aperture).toFixed(1);

export const limitingMagnitude = (telescope) =>
  telescope && (7.7 + 5 * Math.log(telescope.aperture / 10)).toFixed(2);

export const getWeightsOfAllComponentsTemp = (setup) => {
  let components = [];
  Object.keys(setup.components).map((category) => {
    components = [...(components || []), ...(setup.components[category] || [])];
  });

  return components.reduce((weight, component) => {
    if (component.__t === "Mount") {
      return weight;
    }
    return weight + component.weight / 1000;
  }, 0);
};

export const dawesLimit = (telescope) => telescope && (116 / telescope.aperture).toFixed(2);

export const cameraMegapixels = camera => {
  if (!camera) return 0;
  const calc = (camera.vertical_resolution * camera.horizontal_resolution) / 1000000;
  return calc.toFixed(2);
};


export const fovCalculator = (camera, telescope, binning = 1) => {
  if (!camera || !telescope) return { horizontalFov: 0,verticalFov: 0  }; // this should probs return false

  if (!camera.pixel_size_width) {
    camera.pixel_size_width = camera.pixel_size;
    camera.pixel_size_height = camera.pixel_size;
  }
  const fovCamResolutionHorizontal = camera && ((camera.pixel_size_width * binning) / telescope.focal_length) * 206;
  const fovCamResolutionVertical = camera && ((camera.pixel_size_height * binning) / telescope.focal_length) * 206;

  const fovCameraFovHorizontal = (fovCamResolutionHorizontal * (camera.horizontal_resolution / binning)) / 3600;
  const fovCameraFovVertical = (fovCamResolutionVertical * (camera.vertical_resolution / binning)) / 3600;


  return {
    horizontalFov: Math.round(fovCameraFovHorizontal * 100) / 100,
    verticalFov: Math.round(fovCameraFovVertical * 100) / 100
  };

};

export const sensorSize = (camera) => {
  if (!camera) return [0, 0];
  if (!camera.pixel_size_width) {
    camera.pixel_size_width = camera.pixel_size;
    camera.pixel_size_height = camera.pixel_size;
  }
  const height = ((camera.vertical_resolution * camera.pixel_size_height) / 1000).toFixed(2);
  const width = ((camera.horizontal_resolution * camera.pixel_size_width) / 1000).toFixed(2);
  return [height, width];
};
