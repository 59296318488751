import React from "react";
import SetupsContainer from "../components/setups/SetupsContainer";
import { Typography } from "@material-ui/core";

const SetupsListPage = () => {
  return (
    <>
      <div className="container">
        <Typography variant="h3" gutterBottom>
          Explore member setups
        </Typography>

        <SetupsContainer />
      </div>
    </>
  );
};

export default SetupsListPage;
