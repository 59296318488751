import React from "react";
import SetupListCard from "./SetupListCard";

const SetupList = (props) => {
  const { setups, profile, edit, setDeleted } = props;
  if (setups === null) return <p>Loading ...</p>;
  return (
    <>
      <ul>
        {setups.map((setup, key) => {
          return setup && <SetupListCard setup={setup} profile={profile} key={key} edit={edit} setDeleted={setDeleted}/>;
        })}
      </ul>
    </>
  );
};

export default SetupList;
