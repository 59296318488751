import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../contexts/auth0-context";
import axios from "axios";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TableCell,
  Typography
} from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});


const renderTable = (component) => {
  return <TableContainer component={Paper}>
    <Table aria-label="technical details table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {componentDetailsList(component)}
      </TableBody>
    </Table>
  </TableContainer>;
};


const componentDetailsList = (component) => {
  return Object.keys(component)
    .map((key, i) => {
        if (key === "__t") return;
        if (key === "__v") return;
        return (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              {key}
            </TableCell>
            <TableCell align="right">{component[key]}</TableCell>
          </TableRow>
        );
      }
    );
};

const ComponentDetailCard = (props) => {
  const [component, setComponent] = useState({});
  const { id } = props;
  const classes = useStyles();


  useEffect(() => {
    async function getData() {
      const result = await axios.get(`/api/components/${id}/`);
      return result.data;
    }

    getData().then((data) => setComponent(data));
  }, [setComponent]);

  if (!component) return <p>Loading ...</p>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {component.manufacturer} {component.model}
        </Typography>
        {renderTable(component)}
      </CardContent>
      <CardActions>
        <Typography>Verified: {(component.verified || false).toString()}</Typography>

      </CardActions>
    </Card>
  );

};

export default ComponentDetailCard;
