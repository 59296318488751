import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../contexts/auth0-context";

import { useForm } from "react-hook-form";
import axios from "axios";
import CustomizedDialogs from "../components/AddComponentDialog";
import { useHistory } from "react-router-dom";
import ComponentCollectionConfigureCard from "./ComponentCollectionConfigureCard";
import Container from "@material-ui/core/Container";
import { Box, TextField, Typography } from "@material-ui/core";
import AddImageToSetupUploadCard from "../setups/AddImageToSetupUploadCard";
import TechDetails from "./TechDetails";



const ConfigureSetupContainer = (props) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const [setup, setSetup] = useState({});
  const [imagingComponents, setImagingComponents] = useState([]);
  const [guidingComponents, setGuidingComponents] = useState([]);
  const [mountComponents, setMountComponents] = useState([]);
  const [setupImageUrl, setSetupImageUrl] = useState("");
  const [tempSetup, setTempSetup] = useState({components: {
    imaging: imagingComponents,
      guiding: guidingComponents,
      mount: mountComponents
  }})
  const { getTokenSilently } = useAuth0();
  let history = useHistory();


  useEffect(() => {
    const fetchSetup = async () => {
      try {
        const res = await axios.get(`/api/setups/${props.setupId}`);
        setImagingComponents([...res.data.components.imaging_components]);
        setGuidingComponents([...res.data.components.guiding_components]);
        setMountComponents([...res.data.components.mount_components]);
        setSetup(res.data);
        setSetupImageUrl(res.data.setup_image_url)
      } catch (e) {
        console.log("error fetching setup", e.message);
      }
    };

    props.setupId && fetchSetup();

  }, [props.setupId]);


  const handleDeleteComponent = (state, component) => {
    if (state === imagingComponents) {
      setImagingComponents(imagingComponents.filter((item) => (item !== component)));
    }
    if (state === guidingComponents) {
      setGuidingComponents(guidingComponents.filter((item) => (item !== component)));
    }
    if (state === mountComponents) {
      setMountComponents(mountComponents.filter((item) => (item !== component)));
    }
  };

  const handleImagingClick = (event, item) => setImagingComponents([...imagingComponents, item]);

  const handleGuidingClick = (event, item) => setGuidingComponents([...guidingComponents, item]);

  const handleMountClick = (event, item) => setMountComponents([...mountComponents, item]);


  const postSetup = async (data) => {
    const token = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const components = {
      components: {
        imaging: imagingComponents,
        guiding: guidingComponents,
        mount: mountComponents
      },
      setup_image_url: setupImageUrl
    };

    Object.assign(data, components);
    let url = "/api/setups";
    let res;

    if (props.setupId) {
      url = `/api/setups/${props.setupId}`;
      res = await axios.patch(url, data, config);
    } else {
      res = await axios.post(url, data, config);
    }

    return history.push(`/setups/${res.data._id}`);
  };


  const updateName = e => {
    setSetup({
      ...setup,
      "name": e.target.value
    });
  };

  const updateDescription = e => {
    setSetup({
      ...setup,
      "description": e.target.value
    });
  };

  const updateSetupImageUrl = e => {
    setSetup({
      ...setup,
      "setup_image_url": e.target.value
    });
  };

  return (
    <>
      <form id={"submit-setup"}  onSubmit={handleSubmit(postSetup)}>
        <Container>

          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            What is the setup designed for? Planetary? Solar? Lunar? DSO?
          </Typography>
          <Box mb={1}>
          <TextField
            error={errors.name}
            id="name"
            name="name"
            label={"Name"}
            helperText={errors.name && "Name required."}
            value={setup.name || ""}
            onChange={updateName}
            fullWidth={true}
            variant="outlined"
            inputRef={register({ required: true })}
          />
          </Box>

          <Box mb={1}>
          <TextField
            error={errors.description}
            id="description"
            name="description"
            label={"Description"}
            helperText={errors.description && "Description required."}
            value={setup.description || ""}
            onChange={updateDescription}
            fullWidth={true}
            variant="outlined"
            inputRef={register({ required: true })}
          />
          </Box>
        </Container>

     <Container>
    <AddImageToSetupUploadCard image={setupImageUrl} title={'Setup Image'} description={'Upload a picture of your setup in all its glory.'} handleImageUpload={setSetupImageUrl}></AddImageToSetupUploadCard>
  </Container>
        <Container>
          <ComponentCollectionConfigureCard title={"Imaging Components"}
                                  componentState={imagingComponents}
                                  handleClick={handleImagingClick} handleDeleteComponent={handleDeleteComponent} subtitles={[{subtitle: 'Telescope', model:'Telescope'}, {subtitle:'Camera', model: 'Camera'}]}/>
        </Container>

        <Container>
          <ComponentCollectionConfigureCard title={"Guiding Components"}
                                            componentState={guidingComponents}
                                            handleClick={handleGuidingClick} handleDeleteComponent={handleDeleteComponent} subtitles={[{subtitle: 'Guide Scope', model:'Telescope'}, {subtitle:'Guide Camera', model: 'Camera'}]} />
        </Container>

        <Container>
          <ComponentCollectionConfigureCard title={"Mount Components"}
                                            componentState={mountComponents}
                                            handleClick={handleMountClick} handleDeleteComponent={handleDeleteComponent} subtitles={[{subtitle:'Mount', model: 'Mount'}]}/>
        </Container>
        {/*<TechDetails setup={tempSetup}></TechDetails>*/}
        <Container>
          <input className="button is-primary is-large" type="submit" />
        </Container>
      </form>
      <section>
        <div className="container has-text-centered">
          {CustomizedDialogs()}
        </div>
      </section>
    </>
  );
};

export default ConfigureSetupContainer;
