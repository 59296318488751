import React, { useState } from "react";
import { Link } from "react-router-dom";
import ComponentList from "../components/ComponentList";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AlertDialog from "../generic/dialogs/AlertDialog";

import { Card, CardContent, Typography, CardActions, Button, Grid, Paper, Box } from "@material-ui/core";


import axios from "axios";
import { useAuth0 } from "../../contexts/auth0-context";


const setupUrl = (id) => {
  return `/setups/${id}`;
};

const editSetupUrl = (id) => {
  return `/setups/edit/${id}`;
};
const userUrl = (id) => {
  return `/user/${id}`;
};


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBottom: 16
  },
  pos: {
    marginBottom: 12
  },
  userInfo: {
    display: "flex",
    padding: 5,
    marginBottom: 12
  },
  avatar: {
    marginRight: 12
  },
  paper: {
    padding: theme.spacing(2),

    color: theme.palette.text.secondary
  }
}));
const SetupListCard = (props) => {
  const { getTokenSilently } = useAuth0();
  const { setup } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    const token = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const url = `/api/setups/${id}`;
    try {
      await axios.delete(url, config);
      props.setDeleted(true);
    } catch (e) {
      console.log("Error deleting setup", e);
    }
    //TODO: close either way - need a toaster at some point!
    handleClose();
  };
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.userInfo}>
              <Avatar className={classes.avatar} alt={setup.user.username} src={setup.user.profile_pic_url} />
              <Typography variant={"subtitle1"} color="textSecondary" gutterBottom>
                {setup.user.username}
              </Typography>
            </div>

            <Typography variant={"h5"} color="textPrimary" gutterBottom>
              {setup.name}
            </Typography>
            <Typography variant={"body1"} color="textSecondary" gutterBottom>
              {setup.description}
            </Typography>

            {setup.setup_image_url ? (<Paper>
              <img src={setup.setup_image_url} />
            </Paper>) : (<Grid container justify="center">
              <Box m={2}>
              <Button component={Link} to={setupUrl(setup._id)} size="large" variant="contained" color={"secondary"}>Learn
                More</Button>
              </Box>
            </Grid>)}

          </Grid>
          <Grid item xs={6}>
            {!props.profile && (
              <Paper className={classes.paper} elevation={3}>
                <Typography variant="h5">Imaging components</Typography>
                <ComponentList components={setup.components.imaging_components} />
                <Typography variant="h5">Guiding components</Typography>
                <Typography title={"Guiding components"} />
                <ComponentList components={setup.components.guiding_components} />
                <Typography variant="h5">Mount components</Typography>
                <ComponentList components={setup.components.mount_components} />
              </Paper>)}
            {setup.setup_image_url && (
              <Grid container justify={"center"}>
                <Box m={2}>
                  <Button component={Link} to={setupUrl(setup._id)} size="large" variant="contained"
                          color={"secondary"}
                  >Learn
                    More</Button>
                </Box>
              </Grid>)}
            {props.edit && (
              <>
                <Button component={Link} to={editSetupUrl(setup._id)} size="small" variant="contained"
                        color={"primary"}>Edit</Button><br />

                <AlertDialog handleDelete={() => handleDelete(setup._id)} open={open} handleClose={handleClose}
                             handleClickOpen={handleClickOpen} title={"Delete this setup?"}
                             description={"  This is a permanent delete, and will occur immediately. This setup and all associated data will no longer be available."} />
              </>
            )}
          </Grid>
        </Grid>

      </CardContent>
      <CardActions>


      </CardActions>
    </Card>
  );

};

export default SetupListCard;
