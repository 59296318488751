import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";

import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";


const componentUrl = (id) => {
  return `/components/${id}`;
};


const ListItemLink = (props) => {
  const { icon, primary, secondary, to, handleDeleteComponent, deleteComponent } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <ListItem dense={true} button={to ? true : false} component={to ? renderLink : null}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} secondary={secondary} />
      {deleteComponent && (<ListItemSecondaryAction onClick={handleDeleteComponent}>
        <IconButton edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>)}
    </ListItem>
  );
};

const ComponentListCard = (props) => {
    const { component, links, handleDeleteComponent, componentState, deleteComponent } = props;
    let linksEnabled = false;
    if (links) {
      linksEnabled = true;
    }
    if (!component) return(<ListItemLink  primary={'No components'} secondary={null}
                                         icon={<ImageIcon />}  />)
    return (
      <>
        {linksEnabled ? (
          <ListItemLink to={componentUrl(component._id)} primary={component.model} secondary={component.manufacturer}
                        icon={<ImageIcon />}
          />) : (
          <ListItemLink primary={component.model} secondary={component.manufacturer} icon={<ImageIcon />}
                        deleteComponent={deleteComponent}
                        handleDeleteComponent={() => handleDeleteComponent(componentState, component)} />)
        }
      </>
    );
  }
;

export default ComponentListCard;
