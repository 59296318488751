import React, { useEffect } from "react";
import ComponentListCard from "./ComponentListCard";
import { makeStyles } from "@material-ui/styles";
import { List } from "@material-ui/core";
import TechDetails from "../setups/TechDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));
const ComponentList = (props) => {
  const classes = useStyles();
  const { components, componentState, links, deleteComponent, handleDeleteComponent } = props;


  // let techSetup = {components: {
  //   imaging_components: []
  //   }};
  //
  // useEffect(() => {
  //   techSetup = Object.assign(techSetup.components.imaging_components, components)
  // }, [componentState]);
  //

  if (components === null) return <p>Loading ...</p>;
  if (!Array.isArray(components)) return null;
  return (
    <List className={classes.root}>
      {components.length > 0 ? components.map((component, key) => {
        return <ComponentListCard component={component} links={links} key={key} deleteComponent={deleteComponent}
                                  handleDeleteComponent={handleDeleteComponent} componentState={componentState} />;
      }) : <ComponentListCard />}
      {/*{components.length === 0 && (<ComponentCard component={{model: 'Use the input to search for a component' }}   />)}*/}
      {/*<TechDetails setup={techSetup} />*/}
    </List>
  );
};

export default ComponentList;
