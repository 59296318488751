import React from "react";
import SetupDetailCard from "../components/setups/SetupDetailCard";
import { Container, Typography } from "@material-ui/core";


const SetupViewPage = (props) => {
  const { id } = props.match.params;
  return (
    <>
      <Typography variant="h3" gutterBottom>
          Astro Setup details
        </Typography>
        <Container>
        <SetupDetailCard id={id} links={true}></SetupDetailCard>
        </Container>
  </>
  );
};

export default SetupViewPage;
