import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "./contexts/auth0-context";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider>
      <App />
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
