import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";
import SimpleFileUpload from "react-simple-file-upload";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 28,
    marginBottom: 12
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));


const AddImageToSetupUploadCard = (props) => {
  const classes = useStyles();

  const handleImageUrl = (url) => {
    props.handleImageUpload(url)
  };


  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant={"h5"}>{props.title}</Typography>
          <Typography variant={"body1"}>{props.description}</Typography>

          {props.image && (<Paper variant="outlined">
            <img src={props.image} />
          </Paper>)}
        </Grid>
        <Grid item xs={6}>
          <SimpleFileUpload
            apiKey="9a848c936d464430ef4c0f825677fdfa"
            onSuccess={handleImageUrl}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};


export default AddImageToSetupUploadCard;
