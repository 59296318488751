import React from "react";
import ComponentDetailCard from "../components/components/ComponentDetailCard";
import { Typography } from "@material-ui/core";

const ComponentViewPage = (props) => {

  const { id } = props.match.params;

  return (
    <div className="container">
      <Typography variant="h3" gutterBottom>
        Component details
      </Typography>
      <ComponentDetailCard id={id}></ComponentDetailCard>
    </div>
  );
};

export default ComponentViewPage;
