import React from "react";
import ProfileContainer from "../components/users/ProfileContainer";

const ViewUserProfilePage = (props) => {
  const { id } = props.match.params;
  return (
    <>
      <ProfileContainer userId={id} />
    </>
  );
};

export default ViewUserProfilePage;
