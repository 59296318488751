import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import AddTelescope from "../forms/addComponent/AddTelescope";
import AddMount from "../forms/addComponent/AddMount";
import AddCamera from "../forms/addComponent/AddCamera";
import axios from "axios";
import { useAuth0 } from "../../contexts/auth0-context";



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);


const AddComponentDialog = () => {
  const { getTokenSilently } = useAuth0();
  const [open, setOpen] = React.useState(false);

  const postComponent = async (data) => {
    const token = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    await axios.post("/api/components", data, config);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  const [state, setState] = React.useState({
    category: "",
    manufacturer: ""
  });

  const handleChange = (event) => {
    setState({
      ...state,
      "category": event.target.value
    });
  };


  const renderComponentForm = () => {
    if (state.category === "telescope") {
      return (<AddTelescope submitHandler={postComponent} handleClose={handleClose}/>);
    }
    if (state.category === "camera") {
      return (<AddCamera submitHandler={postComponent} handleClose={handleClose}/>);
    }
    if (state.category === "mount") {
      return (<AddMount submitHandler={postComponent} handleClose={handleClose}/>);
    }
  };


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Cant find component?
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add a custom component
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            If we haven't got a component in our database, feel free to add one here! This will help anybody else who is looking for the component.
            Try to be accurate with the information, and certainly include the weight! If for some reason you cant add a component here, please use the contact us button as the bottom of the page to email us directly.
          </Typography>


          <div>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="category">Category</InputLabel>
              <Select
                native
                value={state.type}
                onChange={handleChange}
                inputProps={{
                  name: "category",
                  id: "category"
                }}
              >
                <option aria-label="None" value="" />
                <option value={"mount"}>Mount</option>
                <option value={"telescope"}>Telescope</option>
                <option value={"camera"}>Camera</option>
              </Select>
            </FormControl>
            {renderComponentForm()}
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
}

export default AddComponentDialog;