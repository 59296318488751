import React from "react";
import Header from "./header/Header";
import Hero from "./hero/Hero";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Footer from "./footer/Footer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTracking } from "./useTracking";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  useTracking('UA-137015003-2')
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Hero />
        <Container component="main" className={classes.main} maxWidth="xl">
          {children}

        </Container>
        <Footer></Footer>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Layout;
