import React from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

const schema = Joi.object({
  type_of_component: Joi.string().valid(
    "mount",
    "telescope",
    "camera"
  )
    .required(),
  model: Joi.string()
    .min(1)
    .max(150)
    .required(),
  manufacturer: Joi.string()
    .min(1)
    .max(150)
    .required(),
  pixel_size: Joi.number()
    .max(50000)
    .required(),
  horizontal_resolution: Joi.number()
    .max(50000)
    .required(),
  vertical_resolution: Joi.number()
    .max(50000)
    .required(),
  weight: Joi.number()
    .max(50000)
    .required()
});


const AddCamera = (props) => {
    const {
      register,
      errors,
      handleSubmit
    } = useForm({ resolver: joiResolver(schema) });

    const postComponent = async (data) => {
      await props.submitHandler(data);
      props.handleClose()
    };

    return (

      <form onSubmit={handleSubmit(postComponent)}>
        <input type="hidden" name="type_of_component" ref={register({ required: true })} value="camera" />
        <div>
          <input
            placeholder="Manufacturer"
            name="manufacturer"
            ref={register({ required: true })}
          />
          <span>{errors.manufacturer?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Model" name="model" ref={register({ required: true })} />
          <span>{errors.model?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Pixel size (µm)" name="pixel_size" ref={register({ required: true })} />
          <span>{errors.pixel_size?.message}</span>
        </div>

        <div>
          <input type="text" placeholder="Horizontal resolution" name="horizontal_resolution"
                 ref={register({ required: true })} />
          <span>{errors.horizontal_resolution?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Vertical resolution" name="vertical_resolution"
                 ref={register({ required: true })} />
          <span>{errors.vertical_resolution?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Weight (g)" name="weight" ref={register({ required: true })} />
          {errors.weight?.message}
        </div>

        <input type="submit" />
      </form>
    );
  }
;


export default AddCamera;
