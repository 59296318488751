import React from "react";
import ConfigureSetupContainer from "../components/setups/ConfigureSetupContainer";
import { useAuth0 } from "../contexts/auth0-context";
import { Container, TypographyVariant } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TechDetails from "../components/setups/TechDetails";

const CreateSetupPage = () => {
  const { isLoading, user, loginWithRedirect } = useAuth0();
  if (!isLoading && !user) {
    return loginWithRedirect;
  }
  return (
    <>
      <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        Create a Setup
      </Typography>
        <ConfigureSetupContainer></ConfigureSetupContainer>

    </>
  );
};

export default CreateSetupPage;
