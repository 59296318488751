import React from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import StatisticsCard from "../components/generic/StatisticsCard";

const NotFoundPage = () => {
  return (
    <>
      <Container>
        <Box m={8} p={12}>
        <Typography variant="h3" align={"center"} gutterBottom>
          404 Page not found!
        </Typography>
        <Typography variant="body1" align={"center"} gutterBottom>
          Sorry! This page is missing! Maybe its behind the moon?
        </Typography>
        <Typography variant="body1" align={"center"}gutterBottom>
        <Button component={Link} to={
            "/"
          } size="large" variant="outlined" color={"secondary"}>Take me home</Button>
        </Typography>
        </Box>
      </Container>

      <StatisticsCard />
    </>
  );
};

export default NotFoundPage;