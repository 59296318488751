import React from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";

const schema = Joi.object({
  type_of_component: Joi.string().valid(
    "mount",
    "telescope",
    "camera"
  )
    .required(),
  model: Joi.string()
    .min(1)
    .max(150)
    .required(),
  manufacturer: Joi.string()
    .min(1)
    .max(150)
    .required(),
  payload_capacity: Joi.number()
    .max(50000)
    .required(),
  mount_type: Joi.string()
    .alphanum()
    .min(3)
    .max(150)
    .required(),
  weight: Joi.number()
    .max(50000)
    .required()
});


const AddMount = (props) => {

    const {
      register,
      errors,
      handleSubmit
    } = useForm({ resolver: joiResolver(schema) });

    const postComponent = async (data) => {
      await props.submitHandler(data);
      props.handleClose();
    };


    return (

      <form onSubmit={handleSubmit(postComponent)}>
        <input type="hidden" name="type_of_component" ref={register({ required: true })} value="mount" />
        <div>
          <input
            placeholder="Manufacturer"
            name="manufacturer"
            ref={register({ required: true })}
          />
          <span>{errors.manufacturer?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Model" name="model" ref={register({ required: true })} />
          <span>{errors.model?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Payload capacity (g)" name="payload_capacity"
                 ref={register({ required: true })} />
          <span>{errors.payload_capacity?.message}</span>
        </div>

        <div>
          <input type="text" placeholder="Mount type" name="mount_type" ref={register({ required: true })} />
          <span>{errors.mount_type?.message}</span>
        </div>
        <div>
          <input type="text" placeholder="Weight (g)" name="weight" ref={register({ required: true })} />
          {errors.weight?.message}
        </div>

        <input type="submit" />
      </form>
    );
  }
;


export default AddMount;
