import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ComponentList from "../components/ComponentList";
import AddComponentSearchInput from "../components/AddComponentSearchInput";
import { CardContent, Grid, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 28,
    marginBottom: 12
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  subtitle: {
    padding: theme.spacing(3, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));


function ComponentTypeSearch(props) {

  const classes = useStyles();

  return <Grid container spacing={3}>
    <Grid item xs={2}>
      <Typography className={classes.subtitle} variant={"subtitle1"}>{props.subtitle}</Typography>
    </Grid>
    <Grid item xs={10}>
      <AddComponentSearchInput componentState={props.componentState} handleClick={props.handleClick}
                               model={props.model} />
    </Grid>
  </Grid>;

}

ComponentTypeSearch.propTypes = {
  componentState: PropTypes.any,
  handleClick: PropTypes.any,
  model: PropTypes.any,
  subtitle: PropTypes.any
};

const ComponentCollectionConfigureCard = (props) => {
  const { subtitles } = props;


  const renderSubTitles = (subtitles = []) => {
    return (
      <>
        {subtitles.map(({ subtitle, model }) => {
            return (<ComponentTypeSearch componentState={props.componentState} handleClick={props.handleClick}
                                         model={model} subtitle={subtitle} />);
          }
        )

        }
      </>
    );

  };


  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container spacing={0}>
        <Typography variant={"h5"}>{props.title}</Typography>
        {renderSubTitles(subtitles)}
      </Grid>
      <Grid item xs={6}>
        <ComponentList components={props.componentState} handleDeleteComponent={props.handleDeleteComponent}
                       deleteComponent={true} componentState={props.componentState} />
      </Grid>
    </Paper>
  );
};


export default ComponentCollectionConfigureCard;
