import React, { useState, useEffect } from "react";
import SetupList from "./SetupList";
import CircularProgress from "@material-ui/core/CircularProgress";

const SetupsContainer = () => {
  const [setups, setSetups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/api/setups/");
      return res.json();
    };

    fetchData().then((res) => setSetups(res))
      .catch((err) => console.log(err));
  }, [setSetups]);

  if (setups.length <= 0) {
    return (
      <CircularProgress />
    );
  }

  return (
    <SetupList setups={setups} />
  );
};

export default SetupsContainer;
