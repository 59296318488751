import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const StatisticsCard = () => {
  const classes = useStyles();

  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    async function getData() {
      const result = await axios.get(`/api/statistics/counts/`);
      return result.data;
    }

    getData().then((data) => setStatistics(data));
  }, [setStatistics]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid item><Typography variant={"h4"}>Community Statistics </Typography> </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant={"h6"}>Telescope Count</Typography> <Typography
              variant={"body1"}>{statistics.telescopeCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper} elevation={3}> <Typography variant={"h6"}>Camera Count</Typography>
              <Typography variant={"body1"} >{statistics.cameraCount}</Typography></Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper}  elevation={3}> <Typography variant={"h6"}>Mount Count</Typography>
              <Typography  variant={"body1"}>{statistics.mountCount}</Typography></Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper} elevation={3}>
              <Typography variant={"h6"}>User Count</Typography> <Typography
              variant={"body1"}>{statistics.userCount}</Typography>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper} elevation={3}> <Typography variant={"h6"}>Setups Count</Typography>
              <Typography variant={"body1"}>{statistics.setupCount}</Typography></Paper>
          </Grid>

        </Grid>
      </Paper>
    </div>
  );
};

export default StatisticsCard;