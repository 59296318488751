import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://astrosetup.com/">
        Astrosetup.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[800]
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <footer className={classes.footer}>
        <Container maxWidth="sm">

          <Typography variant="body1">If you have any feedback / suggestions or bug problems</Typography> <Button
          variant="contained"
          size="large"
          color="secondary"
          target="_top"
          rel="noopener noreferrer"
          href={`mailto:andy@astrosetup.com`}
        >
          <Typography variant="button" style={{ fontSize: "0.69rem" }}>
            Contact Us
          </Typography>
        </Button>
          <Copyright />
        </Container>
      </footer>
    </>
  );
};

export default Footer;