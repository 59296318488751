import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../contexts/auth0-context";

const UpsertUser = () => {
  const [hasError, setErrors] = useState(false);
  const { user, isLoading, getTokenSilently } = useAuth0();

  const postUser = async (user) => {
    if (!user) return;
    const token = await getTokenSilently();

    const res = await fetch("/api/identify", {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    });
    console.log({ user: user });
    return res.json();

  };

  useEffect( () => {
    if (isLoading) {
      return;
    }

     postUser(user).catch((err) => {
       console.log(err)
     });
  });

  return <>{!isLoading}</>;
};
export default UpsertUser;
