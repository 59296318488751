import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../contexts/auth0-context";
import ComponentList from "../components/ComponentList";
import TechDetails from "./TechDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";

import { Card, CardContent, Typography, CardActions, Grid, Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const SetupDetailCard = (props) => {
  const [setup, setSetup] = useState();
  const classes = useStyles();
  const {id, profile} = props;

  useEffect(() => {
    async function getData() {
      const result = await fetch(`/api/setups/${id}/`);
      return result.json()
    }

    getData().then((res) => setSetup(res))
      .catch((err) => console.log(err));;
  }, [id, setSetup]);


  if (!setup) {
    return (
      <CircularProgress />
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Avatar alt={setup.user.username} src={setup.user.profile_pic_url} />
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {setup.name} by {setup.user.username}
            </Typography>
            <Paper variant="outlined">
              <img src={setup.setup_image_url} />
            </Paper>
            <TechDetails setup={setup} />
          </Grid>
          <Grid item xs={6}>
            {!profile && (
              <div className="column">
                <Typography>Imaging Components</Typography>
                <ComponentList components={setup.components.imaging_components} links={true} />
                <Typography>Guiding components</Typography>
                <ComponentList components={setup.components.guiding_components} links={true} />
                <Typography>Mount Components</Typography>
                <ComponentList components={setup.components.mount_components} links={true} />
              </div>)}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {/*Like / follow / more like this / tags*/}
        {/*<Button component={Link} to={setupUrl(setup._id)} size="small" variant="contained" color={"primary"}>Learn*/}
        {/*  More</Button>*/}
      </CardActions>
    </Card>
  );
};

export default SetupDetailCard;
