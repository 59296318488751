import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../contexts/auth0-context";
import SetupList from "../setups/SetupList";
import { Container, Typography } from "@material-ui/core";

const debug = (x) => {
  return Object.keys(x)
    .filter((thing) => x[thing])
    .map((item, i) => (
      <ul key={i} className="report">
        {item !== null && (
          <li key={i}>
            {item}: {x[item]}
          </li>
        )}
      </ul>
    ));
};

const ProfileContainer = (props) => {
  const id = props.userId;
  const { isLoading, isAuthenticated,  getTokenSilently } = useAuth0();
  const [deleted, setDeleted] = useState(false)
  const [user, setUser] = useState({});
  const [hasError, setErrors] = useState(false);

  async function getData() {
    let result, url;
    if (!id) {
    url = `/api/users/profile`
    } else {
      url = `/api/users/${id}/setups`
    }

    let token;
    try {
      token = await getTokenSilently();
    } catch (e) {
      setErrors(e);
    }
    try {
      result = await fetch(url, {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      result
        .json()
        .then((res) => {
          setUser(res)
          setDeleted(false)
        })
        .catch((err) => setErrors(err));
    } catch (e) {
      setErrors(e);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }
    getData();
  }, [setUser, isAuthenticated, deleted]);

  console.log({url: user})
  if (!isAuthenticated) return <p>Please log in to view member details.</p>;
  if (hasError) return <p>{debug(hasError)}</p>;

  return (
    isAuthenticated && user && (
      <Container>
        <Typography variant="h3" gutterBottom>
          Member Profile
        </Typography>

        <div>
          <div className="media">
            <div className="media-left">
              <figure className="image is-24x24">
                <img src={user.profile_pic_url} alt={`${user.username}'s profile picture`} />
              </figure>
            </div>
            <div className="media-content">
              <div className="content">
                <p>Username: {user.username}</p>
              </div>
            </div>
          </div>


          <div className="media">
            <div className="media-left">
              {/*<figure className="image is-24x24">*/}
              {/*  <img src="placeholder/icons/phone.svg" alt="" />*/}
              {/*</figure>*/}
            </div>
            <div className="media-content">
              <div className="content">
                <p>SETUPS</p>
              </div>
            </div>
          </div>
        </div>
        <>
         {user.setups && <SetupList setups={user.setups} profile={true} edit={true} setDeleted={setDeleted}/> }

        </>
      </Container>
    )
  );
};

export default ProfileContainer;
