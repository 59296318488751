import React from "react";
import { useAuth0 } from "../../../contexts/auth0-context";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Grid, Container, Button } from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: "url(\"/andromeda-hero.png\")",
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  }
}));

export default function Hero() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const classes = useStyles();
  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Astrosetup.com
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Astrophotography and astronomy hardware community.
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Love your telescope? Share it with others!
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              {!isAuthenticated && !isLoading ? (
                <Button variant="contained" color="primary" onClick={loginWithRedirect}>
                  Sign Up to share!
                </Button>
              ) : (
                <Button variant="contained" color="primary" component={RouterLink} to="/create" >
                  Share a Setup
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button variant="contained" color={"primary"} component={RouterLink} to="/setups">
                Browse Setups
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
    ;
}
