import React from "react";
import ConfigureSetupContainer from "../components/setups/ConfigureSetupContainer";
import { useAuth0 } from "../contexts/auth0-context";
import Typography from "@material-ui/core/Typography";

const SetupEditPage = (props) => {
  const { isLoading, user, loginWithRedirect } = useAuth0();
  if (!isLoading && !user) {
    return loginWithRedirect;
  }
  return (
    <>
      <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
        Edit a Setup
      </Typography>
        <ConfigureSetupContainer setupId={props.match.params.id}></ConfigureSetupContainer>
    </>
  );
};

export default SetupEditPage;
