import React from "react";
import {
  cameraMegapixels,
  dawesLimit,
  focalRatio, fovCalculator,
  getCamera,
  getMount, getMountPayloadCapacity,
  getTelescope, getWeightsOfAllComponentsTemp,
  limitingMagnitude, sensorSize
} from "../../lib/calculatorHelper";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableCell, withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
}
}))(TableCell);


const TechDetails = (props) => {
  const { setup } = props;

  const telescope = getTelescope(setup.components.imaging_components);
  const camera = getCamera(setup.components.imaging_components);
  const mount = getMount(setup.components.mount_components);

  const getFocalRatio = focalRatio(telescope);
  const getDawesLimit = dawesLimit(telescope);
  const getLimitingMagnitude = limitingMagnitude(telescope);
  const componentWeights = getWeightsOfAllComponentsTemp(setup);
  const mountCapacity = getMountPayloadCapacity(mount);
  const cameraMp = cameraMegapixels(camera);
  const cameraSensorSize = sensorSize(camera);

  const genRow = (key, value) => {
    return (<TableRow key={key}>
      <TableCell component="th" scope="row">
        {key}
      </TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="setup technical information" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Technical Details</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {genRow("Focal Ratio", getFocalRatio)}
          {genRow("Dawes' Limit", `${getDawesLimit} arc/secs`)}
          {genRow("Field of View", `${fovCalculator(camera, telescope).horizontalFov}\u00b0 x ${fovCalculator(camera, telescope).horizontalFov}\u00b0`)}
          {genRow("Limiting magnitude", getLimitingMagnitude)}
          {genRow("Camera Megapixels", `${cameraMp}MP`)}
          {genRow("Camera Sensor Size", `${cameraSensorSize[0]}mm * ${cameraSensorSize[1]}mm`)}
          {genRow("Max Mount Capacity", `${mountCapacity} Kg`)}
          {genRow("Component Weights", `${componentWeights} Kg* (calculated)`)}
        </TableBody>
      </Table>
    </TableContainer>

  );
};


export default TechDetails;
