import React from "react";
import { useAuth0 } from "../../../contexts/auth0-context";
import "./Header.css";
import { Link } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  }
}));


export default function Header() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            astrosetup.com
          </Typography>
          <nav>
            <Link variant="button" color="textPrimary" to='/' className={classes.link}>
              Home
           </Link>
            <Link variant="button" color="textPrimary" to='/setups' className={classes.link}>
              Setups
             </Link>
            <Link variant="button" color="textPrimary" to='/about' className={classes.link}>
              About
        </Link>
          </nav>
          {!isAuthenticated ? (<Button onClick={loginWithRedirect} color="primary" variant="outlined" className={classes.link}>
            Register
          </Button>)
           :
            (<>
              <Link variant="button" color="textPrimary" to="/account" className={classes.link}>
                My Account
              </Link>
              <Button onClick={() => logout({ returnTo: window.location.origin })} color="primary" variant="outlined" className={classes.link}>
                Logout
              </Button>
            </>)}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
