import React, { useEffect, useState } from "react";
import SetupsContainer from "../components/setups/SetupsContainer";
import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import StatisticsCard from "../components/generic/StatisticsCard";



const HomePage = () => {

  return (
    <>

      <Container>
        <Typography variant="h3" gutterBottom>
          Latest member setups
        </Typography>
        <SetupsContainer />
      </Container>
      <StatisticsCard />
    </>
  );
};

export default HomePage;
